import React from 'react'

const LinkIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="none"
            viewBox="0 0 44 44"
        >
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.75"
                clipPath="url(#clip0_34_26)"
            >
                <path d="M23.49 30.789l7.794 2.337m-20.41-20.41l2.337 7.79-2.338-7.79zM21.419 22.581l10.558-10.558"></path>
                <path d="M10.083 12.833a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zM33.917 12.833a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zM33.917 36.667a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zM15.583 36.667a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z"></path>
            </g>
            <defs>
                <clipPath id="clip0_34_26">
                    <path fill="#fff" d="M0 0H44V44H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkIcon