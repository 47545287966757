import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import Features from './Features';
// import contract_mng from "../../assets/contract_mng.png";
import contract_mng from '../../assets/contactMgnt.png'
import LaptopSlider from './LaptopSlider';

const ContractManagement = () => {
    return (
        <>
            <Container maxWidth="lg" sx={{ pt: 10, py:20 }}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant='h3' sx={{ color: '#0B132A', fontWeight: "700" }} >Sundeus Contract Management (S-CLM)</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant='body1' sx={{ color: '#4F5665', mt: 2, textAlign: { xs: 'justify', md: 'start' }, }} >We transform contract into strategic assets, giving small and global enterprises new capabilities to maximize revenue realization, reduce opportunity costs and minimize contractual risks.</Typography>
                    </Grid>
                </Grid>

                <Divider color="#F47920" sx={{ height: 4, width: '200px', my: '2rem' }} />

                <Grid container>
                    <Grid item xs={12} md={6} lg={7}>
                        <Typography variant='h5' sx={{ fontWeight: '700', color: '#0B132A', my: 2 }} >OVERVIEW</Typography>
                        <Typography variant='body1' sx={{ color: '#4F5665', textAlign: { xs: 'justify', md: 'start' }, }} >"S-CLM" is a Google cloud based contract management solution. It is a configurable product with top of the class technology basket. We bring mix of best of the latest technologies & knowledge to fix both old and new challenges faced by teams handling your day to day contracts. Its armed with predictive analysis capabilities using Artificial Intelligence principles. Be it your sales team, legal team, procurement team or human resources team, with frequently changing government norms, narrow sales margins & cut throat competition S-CLM will keep your unwanted costs at the lowest & help you streamline your contracting processes.</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <Box component='img' src={contract_mng} alt='contract-management' sx={{ py: 5, display: 'flex', justifyContent: 'flex-end', width: { xs: '100%' }, height: { xs: "100%" } }} />

                        {/* </Box> */}
                        {/* <LaptopSlider /> */}
                    </Grid>
                </Grid>
            </Container>
            <Features />
        </>
    )
}

export default ContractManagement;