import { List, ListItem } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import DocumentIcon from '../../@svg/DocumentIcon'
import LinkIcon from '../../@svg/LinkIcon'
import MonitorIcon from '../../@svg/MonitorIcon'
import SettingIcon from '../../@svg/SettingIcon'
import FeaturedClients from './FeaturedClients'

const cardData = [
    {
        icon: <SettingIcon />,
        title: 'Configurable Platform',
        body: ['Easy Contract Configurations',
            'User Access Control',
            'Contract Version Control',
            'Easy Rules Configuration']
    },
    {
        icon: <MonitorIcon />,
        title: 'Advanced Features',
        body: ['Elastic Search Enabled',
            'Personalized Dashboard',
            'User Defined Reports',
            'Application for Executives']
    },
    {
        icon: <LinkIcon />,
        title: 'Workflow Automation',
        body: ['Rule based Approval',
            'Obligation Management',
            'Template Management',
            'Clause Library Management']
    },

    {
        icon: <DocumentIcon />,
        title: 'Latest Techonology Stack',
        body: ['Google based Saas Solution',
            'Open API Architecture',
            'ELK Stack',
            'AI Capabilities']
    }
]

const FeaturesCard = () => {

    return (
        <Grid container sx={{ backgroundColor: '#161C24', justifyContent: 'center' }}>
            <Container maxWidth='lg' sx={{ justifyContent: 'center' }}>
                <Typography variant='h4' sx={{ color: 'white', fontWeight: 700, textAlign: 'center', pb: 7, py: { xs: 5 } }}>Four Basic Elements of Sundeus CLM</Typography>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 0, lg: 2, md:4 }} xs={12} md={12} lg={12} sx={{ justifyContent: 'center', }}>
                    {cardData.map((item: any, index: number) => (<Grid key={index} item xs={12} md={6} lg={3}>
                        <Card sx={{ backgroundColor: "#212B36" }}>
                            <CardHeader avatar={item.icon} />
                            <CardContent>
                                <Typography variant="body1" sx={{ color: "white", fontWeight: 600 }}>{item.title}</Typography>
                                <List sx={{ mt: 3 }}>
                                    {item.body.map((itm: string, idx: number) => (<ListItem disablePadding key={idx}>
                                        <Typography variant="body2" sx={{ color: "white", lineHeight: '2rem' }}>{itm}</Typography>
                                    </ListItem>))}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>))}
                </Grid>
                <FeaturedClients />

            </Container>
        </Grid>

    )
}

export default FeaturesCard