import { Grid, List, Typography, Box, ListItem, Container } from '@mui/material'
import OfferingTwo from "../../assets/offering2.png"

const ui_ux = [
    'UX / UI Audit',
    'Business Model Validation & Product Concepts',
    'UX Reserach',
    'Usability Testing',
    'User Journey',
    'Empathy Map',
    'Visual Design'
]

const UiSection = () => {
    return (
        <>
            <Grid sx={{ backgroundColor: '#161C24', }}>
                <Container maxWidth="lg" sx={{ py: 5 }}>
                    <Grid container>
                        <Grid item lg={6} md={12} xs={12}>
                            <Typography variant='h5' sx={{ color: 'white', fontWeight: '700', my: "2rem" }}>UI / UX</Typography>
                            <Typography variant='body2' sx={{ color: 'white', }}>What differentiates good UX from great UX? It's expertise.
                            </Typography>
                            <Typography variant='body2' sx={{ color: 'white', py: 2 }}>Our user experience consultants offer a wide range of consulting and training services.
                            </Typography>
                            <List sx={{ listStyleType: 'disc', pl: 4, color: 'white' }}>
                                {ui_ux.map((item: string, index: number) => (
                                    <ListItem key={index} disablePadding sx={{ display: 'list-item', fontSize: '16px', }}>
                                        {item}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item lg={6} md={12} xs={12}>
                            <Box sx={{ py: 5, display: 'flex', justifyContent: {lg:'flex-end', xs:'center'}, }}>
                                <Box component="img" src={OfferingTwo} alt="Second Image" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default UiSection