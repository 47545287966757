import { Container, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import React from 'react'
import Logo from '../../svg/Logo'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <Grid sx={{ backgroundColor: '#161C24' }}>
            <Container maxWidth="lg">
                <Grid container sx={{ justifyContent: 'space-between', py: 5 }} >
                    <Grid xs={12} md={6} lg={6}>
                        <Logo />
                        <Typography variant='body2' sx={{ color: 'white' }}>Make Things Happen With S-CLM</Typography>
                        <Grid container spacing={2} sx={{ marginTop: 0.5 }}  >
                            <Grid item>
                                <Tooltip title={'LinkedIn'}>
                                    <Link href='https://www.linkedin.com/company/sundeus-technologies/' target={'_blank'}>
                                        <IconButton>
                                            <LinkedInIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'Twitter'}>
                                    <Link href='https://twitter.com/sundeus_pvt' target={'_blank'}>
                                        <IconButton>
                                            <TwitterIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={'Instagram'}>
                                    <Link href='https://www.instagram.com/sundeustechnology2404/' target={'_blank'}>
                                        <IconButton>
                                            <InstagramIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Grid>
                            {/* <Grid item>
                                <GitHubIcon sx={{ color: 'white' }} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} spacing={2} sx={{ alignItems: { xs: 'flex-start', md: 'flex-end', lg: 'flex-end' } }} xs={12} md={6} lg={6}>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: 'white', textAlign: { xs: 'start', md: 'end', lg: 'end' } }} >Office No.909 Gera Imperium Rise, Wipro Circle Hinjewadi Phase 2 Pune (MH) – 411057</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: 'white' }} >Tel. +917768001429</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: 'white' }} >Email: contact@sundeus.com</Typography>

                        </Grid>
                        <Grid item>
                            <Typography variant='body2' sx={{ color: '#F47920' }} >© 2021 by Sundeus Technologies</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Footer