import { Box, createTheme, CssBaseline, Divider, PaletteOptions, ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import About from "./components/about/About";
import Career from "./components/careers/Career";
import ContractManagement from "./components/contractManagement/ContractManagement";
import Home from "./components/home/Home";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Offerings from "./components/offerings/Offerings";


const _paletteOptions: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#F47920',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: 'rgba(244, 121, 32, 0.35)',
  }
}

const themeOptions: any = {
  palette: _paletteOptions,
  shape: {

  },
  typography: {
    fontFamily: [
      'Rubik',
    ].join(','),
    body2: {
      fontSize: 16,
      fontWeight: 400,
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        }
      }
    },
  }
}


function App() {
  const themeConfig = createTheme(themeOptions)
  return (
    <ThemeProvider theme={themeConfig}>
      <Box>
        <CssBaseline />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contract-management" element={<ContractManagement />} />
          <Route path="/career" element={<Career />} />
          <Route path="/offering" element={<Offerings />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
