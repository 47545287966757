import { Divider, Grid, List, Typography, Box, ListItem, Button, Container } from '@mui/material'
import OfferingThree from "../../assets/offering3.png"


const ai_ml = [
    'Google AI Hub',
    'Azure Cognitive Services',
    'Open Source Framework (Tensorflow, PyTorch)',
]


const AISection = () => {
    return (
        <>
            <Container maxWidth="lg" sx={{ py: 5, }}>
                <Grid container>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography variant='h5' sx={{ color: '#0B132A', fontWeight: '700', my: "2rem" }}>Artificial Intelligence (AI/ML)</Typography>
                        <Typography variant='body2' sx={{ color: '#4F5665',textAlign:{xs:'justify', md:'start'} }}>Artificial Intelligence is bringing value to the business through deep insight, decision making & intelligent automation. Sundeus AI Consultants are upto the mark with the industry leading AI research and technology innovations. We have the right expertise supported by our AI Lab to provide required business driven AI features and capabilities. Our key expertise area includes:</Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4, py: 5 }}>
                            {ai_ml.map((item: string, index: number) => (
                                <ListItem key={index} disablePadding sx={{ display: 'list-item', fontSize: '16px', color: '#4F5665', }}>
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    <Grid item lg={6} md={12} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: {lg:'flex-end', xs:'center'}, py: 5 }}>
                            <Box component="img" src={OfferingThree} alt="Second Image" sx={{width:'100%'}} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default AISection