import { InputBase, TextField } from '@mui/material';
// import TextField from '@mui/material/TextField';
import { useFormContext } from "react-hook-form";


type MyTextFieldProps = {
    label?: string,
    name: string,
    required?: boolean,
    placeholder?: string,
    type?: string
}

export default function MyTextField({ label, name, required, placeholder, type }: MyTextFieldProps) {

    const { register, formState: { errors } } = useFormContext();
    const Label = () => <>{label} {required && <span style={{ color: "red" }}>*</span>}</>;

    return (
        <TextField
            label={<Label />}
            {...register(name)}
            variant="outlined"
            error={errors[name] ? true : false}
            placeholder={placeholder}
            helperText={errors[name] && (errors as any)[name].message}
            fullWidth
            // size='small'
            type={type || "text"}
            sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1, }}
        />
    )
};