import { Divider, Grid, Typography, Container } from '@mui/material'


const About = () => {
    return (
        <>
            <Container maxWidth="lg" sx={{ py: 20 }}>
                <Grid container>
                    <Grid item lg={12}>
                        <Typography variant='h3' sx={{ color: '#0B132A', fontWeight: "700" }} >Vision</Typography>
                        <Divider color="#F47920" sx={{ height: 4, width: '170px', my: '2rem' }} />
                    </Grid>
                    <Grid item lg={12} sx={{ pb: 17 }}>
                        <Typography variant='body1' sx={{ color: '#4F5665', lineHeight: '1.7rem', textAlign: { xs: 'justify', md: 'none' } }} >We are a customer focused organisation and We derive pride in delivering value to business. We strive hard to ride the technology wave with our partners, employees and clients. The Company was structured in early 2018 with a goal to provide consuliting in upcoming technologies and create a solution for Contract Life Cycle space.<br></br>
                            Sundeus is partnering up with Google to bring its expertise to the cloud space. The problem are committed to delivering value and not a mere a software solution for the client business. We envision ourselves as a knowledge base for you technical and contract management domain queries. We also belive that happy employee results in satisfied customer, so we are among the very few companies in the world is experiment with a 4 days a week work schedule.
                            Sundeus workflow brings expert level knowledge base to implement solutions to your IT business problem.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default About