import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Logo from '../../svg/Logo';
import HeaderLogo from '../../svg/HeaderLogo';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png'
import ContactUsModal from './ContactUsModal';

const pages = [{ name: 'Home', path: '/' }, { name: 'Contract Management', path: '/contract-management' }, { name: 'Offerings', path: '/offering' }, { name: 'About', path: '/about' }, { name: 'Career', path: '/career' }];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [showModal, setshowModal] = React.useState(false)
    const location = useLocation();


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 'none', borderRadius:'0' }} >
            <Container maxWidth="lg">
                <ContactUsModal show={showModal} handleClose={() => setshowModal(false)} />
                <Toolbar disableGutters>
                    <IconButton component={Link} to='/' sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Box component={'img'} src={logo} sx={{ maxHeight: 100, width: 200 }} />
                    </IconButton>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: 'black' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.path} sx={{color:"#4F5665"}} >
                                    <Typography textAlign="center" sx={{color:"#4F5665"}}>{page.name}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={() => setshowModal(true)} >
                                <Typography textAlign="center">Contact Us</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <IconButton component={Link} to='/' sx={{ display: { xs: 'flex', md: 'none' }, mr: 10 }}>
                        <Box component={'img'} src={logo} sx={{ height: 50, width: 170 }} />
                    </IconButton>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 20 }}>
                        {pages.map((item, index) => (
                            <Button
                                component={Link}
                                to={item.path}
                                key={index}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: location.pathname == item.path ? '#F47920' : '#4F5665', display: 'block', textTransform: 'capitalize', mx: 1 }}
                            >
                                <Typography variant='body2' sx={{ fontWeight: location.pathname == item.path ? '700' : '400', }}>
                                    {item.name}
                                </Typography>
                            </Button>
                        ))}
                        <Button onClick={() => setshowModal(true)} variant='outlined' sx={{ borderRadius: 10, px: 4, my: 1.7, ml: 7, color: '#F47920', borderColor: '#F47920', borderWidth: 2, fontSize: "16px", fontWeight: 600, textTransform: 'capitalize' }}>Contact</Button>
                    </Box>

                    {/* <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header