import { Box, Grid } from '@mui/material'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const logos = [
  { id: 1, name: 'digivito', path: require('../../assets/digivito.png') },
  { id: 2, name: 'wise-wings', path: require('../../assets/wide-wings.png') },
  { id: 3, name: 'coxiant', path: require('../../assets/coxiant.png') },
  { id: 4, name: 'benchmark', path: require('../../assets/benchmark.png') },
  { id: 5, name: 'gardiant', path: require('../../assets/gardiant.jpeg') },
  { id: 6, name: 'bookkeep', path: require('../../assets/bookkeep.png') },
  { id: 7, name: 'vjcs', path: require('../../assets/vjcs.png') },
  { id: 8, name: 'digivito', path: require('../../assets/digivito.png') },
  { id: 9, name: 'wise-wings', path: require('../../assets/wide-wings.png') },
  { id: 10, name: 'coxiant', path: require('../../assets/coxiant.png') },
  { id: 11, name: 'benchmark', path: require('../../assets/benchmark.png') },
  { id: 12, name: 'gardiant', path: require('../../assets/gardiant.jpeg') },
  { id: 13, name: 'bookkeep', path: require('../../assets/bookkeep.png') },
  { id: 14, name: 'vjcs', path: require('../../assets/vjcs.png') },
  { id: 15, name: 'digivito', path: require('../../assets/digivito.png') },
  { id: 16, name: 'wise-wings', path: require('../../assets/wide-wings.png') },
  { id: 17, name: 'coxiant', path: require('../../assets/coxiant.png') },
  { id: 18, name: 'benchmark', path: require('../../assets/benchmark.png') },
  { id: 19, name: 'gardiant', path: require('../../assets/gardiant.jpeg') },
  { id: 20, name: 'bookkeep', path: require('../../assets/bookkeep.png') },
  { id: 21, name: 'vjcs', path: require('../../assets/vjcs.png') },

]


const ClientCorousel = () => {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 500,
    cssEase: "linear"
  };

  return (

    <Slider {...settings}>

      {logos.map((item: any, index: number) =>
        (<Box component='img' sx={{ height: 233, width: 350, maxHeight: { xs: 120, md: 167 }, maxWidth: { xs: 120, md: 250 }, objectFit: 'contain' }} src={item.path} alt={item.name} />))}

    </Slider>



  )
}

export default ClientCorousel