import { Divider, Grid, List, Typography, Box, ListItem, Container } from '@mui/material'
import EnterpriseArch from '../../@svg/EnterpriseArch'
import OfferingOne from "../../assets/offering1.png";
import AISection from './AISection'
import UiSection from './UiSection'

const enterpriseArch = [
    'IT Strategy and Roadmap',
    'EA Assessment and Application Portfolio Rationalisation (APR)',
    'Future State Architecture (FSA)',
    'Enterprise Architecture Governance',
    'Reference Architecture',
    'Enterprise Solution Architecture',
    'Digital Maturity Assessment',
]



const Offerings = () => {
    return (
        <>
            <Container maxWidth="lg" sx={{ pt: 20,}}>
                <Grid container>
                    <Grid item lg={6}>
                        <Typography variant='h3' sx={{ color: '#0B132A', fontWeight: "700", textAlign:{xs:'center', md:'start'} }} >Sundeus Offerings</Typography>
                        <Divider color="#F47920" sx={{ height: 4, width: '170px', my: '2rem', ml:{xs:12, md:0, lg:0} }} />
                    </Grid>
                    <Grid item lg={6}>
                        <Typography variant='body1' sx={{ color: '#4F5665', textAlign:{xs:'justify', md:'start'},  }} >We at 'Sundeus' use Enterprise Architecture to design the holistic and detailed view of the enterprise resulting in better, faster and cheaper business/IT opeartions, better return on existing investment and reduced risk for future investment.</Typography>
                    </Grid>
                </Grid>
                <Typography variant='h5' sx={{ fontWeight: '700', color: '#0B132A', my: 4 }} >Enterprise Artchitecture</Typography>
                <Grid container sx={{ pb: 10 }}>
                    <Grid item lg={8}>
                        <Typography variant='body1' sx={{ color: '#4F5665', textAlign:{xs:'justify', md:'start'},}} >Sundeus EA Consulting is an amalgamation of practitioners with decades of enterprise architecture experience and young energetic minds, who are full of passion and energy to do something out of the box and new to solve customer problems. The core of architecture is based on business outcome-driven, customer-centric and future-ready.</Typography>
                        <Box>
                            <List sx={{ listStyleType: 'disc', pl: 4, }}>
                                {enterpriseArch.map((item: string, index: number) => (<ListItem key={index} disablePadding sx={{ display: 'list-item', fontSize: '16px' }}>
                                    {item}
                                </ListItem>))}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box component='img' src={OfferingOne} alt="Offering One" sx={{pt:7, width:'100%'}} />
                    </Grid>
                </Grid>
            </Container>

            {/* UI/UX Section  */}
            <UiSection />

            {/* AI/ML Section */}
            <AISection />


        </>
    )
}

export default Offerings