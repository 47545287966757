import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import FeaturesCard from './FeaturesCard';
import ReviveSection from './ReviveSection';
import home1 from "../../assets/home1.png";
import ContactUsModal from '../layout/ContactUsModal';
import React from 'react';

const Home = () => {
  const [showModal, setshowModal] = React.useState(false)
  return (
    <>
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container>
          <ContactUsModal show={showModal} handleClose={() => setshowModal(false)} />
          <Grid item xs={12} md={6} lg={6} sx={{ justifyContent: { xs: 'center', md: "flex-start", }, pt: 15 }}>
            <Typography variant='h3' sx={{ color: '#0B132A', fontWeight: "1000", textAlign: { xs: 'center', md: 'start' }, }} >Make Things Happen With S-CLM</Typography>
            <Stack sx={{ my: 4, alignItems: { xs: 'center', md: 'flex-start', lg: 'flex-start' } }}>
              <Typography variant='body2' sx={{ textAlign: { xs: 'center' } }}>No More Physical Files & Stickers, Time to Put Down Your Pen!</Typography>
              <Typography variant='body2'>Go With Electronic Signatures.</Typography>
              <Button variant="contained" onClick={() => setshowModal(true)} sx={{
                borderRadius: '10px', py: 2,
                px: 7, backgroundColor: "#F47920",
                boxShadow: "0px 15px 20px rgba(244, 121, 32, 0.35)", my: { xs: 5, },
                textTransform: 'capitalize',
              }}>Request demo</Button>
            </Stack>

          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: 3 }}>
            <Box component='img' src={home1} alt="Home Image" sx={{ display: 'flex', justifyContent: 'flex-end', width: { xs: '100%' }, height: { xs: "100%" }, objectFit: 'contain' }} />
          </Grid>
        </Grid>
      </Container>
      <FeaturesCard />
      <Box sx={{ p: 10, mt: 20 }}></Box>
      {/* <ReviveSection /> */}

    </>
  )
}

export default Home;