export const openingsList = [
  {
    position: 'Java Developer (1-3 years)',
    noOfPosition: '03',
    location: 'Pune',
    postedDate: 'October 24, 2022',
    experienceRequired: '3',
    jobType: 'Full Time',
    requiredSkills: [
      'Strong expertise with Java, Spring, Spring Boot and Spring MVC.',
      'Good Experience in relational database like MySql, SQL Server etc.',
      'Experience with JavaScript frameworks like Angular 8 and above, React etc.',
      'Experience with RESTful services is a must.',
      'Experience in DevOps tools like Jenkins etc.',
      'Knowledge of at least one of the cloud provider (Google, AWS, Azure).',
      'Self starter and good team player.',
      'Good communication skills.',
    ],
  },
  {
    position: 'Senior Java Developer',
    noOfPosition: '02',
    location: 'Pune',
    postedDate: 'October 24, 2022',
    experienceRequired: '3',
    jobType: 'Full Time',
    requiredSkills: [
      'Strong expertise with Java, Spring, Spring Boot and Spring MVC.',
      'Good Experience in relational database like MySql, SQL Server etc.',
      'Experience with JavaScript frameworks like Angular 8 and above, React etc.',
      'Experience with RESTful services is a must.',
      'Experience in DevOps tools like Jenkins etc.',
      'Knowledge of at least one of the cloud provider (Google, AWS, Azure).',
      'Self starter and good team player.',
      'Good communication skills.',
    ],
  },
  {
    position: 'Full Stack Developer (.Net , C#, React.js)',
    noOfPosition: '02',
    location: 'Pune',
    postedDate: 'October 23, 2022',
    experienceRequired: '2+',
    jobType: 'Full Time',
    requiredSkills: [
      '2+ Yrs of project experience in .Net , C#, React.js.',
      'Proficiency in building REST services using C# and .Net Core web API',
      'Experience with one or more PostgreSQL/mongoDB',
      'Experience of developing highly available and scalable backend services',
      'Experience of hosting services in cloud AWS/Azure/GCP',
      'Thorough understanding of React.js and its core principles',
      'Strong proficiency in JavaScript, including DOM manipulation',
      'Familiarity with more current specifications of ECMAScript',
      'Experience with common front-end development tools such as Babel, Webpack, NPM, etc.',
      'In-depth knowledge of JavaScript, CSS, HTML and front-end languages.',
    ],
  },
  {
    position: 'Software Engineer (.NET)',
    noOfPosition: '02',
    location: 'Pune',
    postedDate: 'October 23, 2022',
    experienceRequired: '1 to 4 yrs',
    jobType: 'Full Time',
    requiredSkills: [
      'Familiarity with the ASP.NET CORE framework',
      'Experience with My SQL and design/architectural patterns (e.g. Model-View-Controller (MVC)',
      'Familiarity with git commands ',
      'Experience on working with Google cloud platform/AWS/Azure (good to have)',
      '2 years of development experience using C# with .NET Core.',
      'Strong background in object oriented development techniques',
      'Familiarity with architecture styles/APIs (REST)',
      'Understanding of Agile methodologies',
      'Excellent troubleshooting and communication skills',
      'Able to work well in a team setting.',
    ],
  },
]
