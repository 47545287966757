import { Box, Button, Grid, InputBase, MenuItem, Modal, Paper, TextareaAutosize, TextField } from '@mui/material'
import React, { useState } from 'react'
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import MyTextField from '../../@common/MyTextField';
import emailjs from 'emailjs-com'


export interface modalProps {
    show: boolean;
    handleClose: () => void;
}

const schema = object().shape({
    firstName: string().required(),
    lastName: string().required(),
    email: string().email().required(),
    company: string().required(),
    subject: string().required(),
    enquiry: string().required(),
    message: string().required()
});

export interface SendEnquiry {
    firstName: string,
    lastName: string,
    email: string,
    company: string,
    subject: string,
    enquiry: string,
    message: string,
}

const ContactUsModal = ({ show, handleClose }: modalProps) => {
    const [value, setValue] = useState('')

    // const handleSubmit = () => console.log(value);

    const { reset } = useForm<SendEnquiry>()

    const methods = useForm<SendEnquiry>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            subject: '',
            enquiry: '',
        },
        resolver: yupResolver(schema)
    });

    const handleSubmit: SubmitHandler<SendEnquiry> = (data: SendEnquiry, event: any) => {

        console.log(data, event);

        emailjs.sendForm('service_96t4dlc', 'template_fsol7fh', event.target || '', 'Lxtzca4DHfMovRSL9')
        event.target.reset()
        handleClose()


        // dispatch(sendEnquiry(data))

        // navigate({
        //     pathname: '/search',
        //     search: `?location=${data.location}&age=${data.age}&home=${data.houseType}&budget=${data.budget}`,

        // })

        // if (Close) Close();

    }

    return (
        <Modal open={show} onClose={handleClose}>
            <Grid container sx={{ justifyContent: 'center', }}>
                <Grid item xs={12} md={6} lg={8}>
                    <Paper sx={{ p: 4, mt: 10}}>
                        <FormProvider {...methods}>
                            <Box component='form' onSubmit={methods.handleSubmit(handleSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        {/* <InputBase sx={{ ml: 1, backgroundColor: 'rgba(145, 158, 171, 0.13)', p: 2, borderRadius: 2 }} fullWidth placeholder="First Name" inputProps={{ 'aria-label': 'enter first name' }} name="firstname" value={ } /> */}
                                        <MyTextField name='firstName' label='First Name' />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        {/* <InputBase sx={{ ml: 1, backgroundColor: 'rgba(145, 158, 171, 0.13)', p: 2, borderRadius: 2 }} fullWidth label="Last Name" inputProps={{ 'aria-label': 'last name' }} name="lastname" value={ } /> */}
                                        <MyTextField name='lastName' label='Last Name' />

                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <MyTextField name='email' label='Email' />

                                        {/* <InputBase sx={{ ml: 1, backgroundColor: 'rgba(145, 158, 171, 0.13)', p: 2, borderRadius: 2 }} fullWidth label="Email" inputProps={{ 'aria-label': 'enter email' }} name="email" value={ } /> */}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <MyTextField name='company' label='Company' />

                                        {/* <InputBase sx={{ ml: 1, backgroundColor: 'rgba(145, 158, 171, 0.13)', p: 2, borderRadius: 2 }} fullWidth label="Company" inputProps={{ 'aria-label': 'enter company' }} name="company" value={ } /> */}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <MyTextField name='subject' label='Subject' />

                                        {/* <InputBase sx={{ ml: 1, backgroundColor: 'rgba(145, 158, 171, 0.13)', p: 2, borderRadius: 2 }} fullWidth placeholder="Subject" inputProps={{ 'aria-label': 'enter subject' }} name="subject" value={ } /> */}
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={6} xs={12}>
                                        <TextField fullWidth select sx={{ borderColor: 'white', "& fieldset": { border: 'none' }, backgroundColor: 'rgba(145, 158, 171, 0.13)', py: 0.5, mx: 1 }} label="Choose Enquiry" {...methods.register('enquiry')} >
                                            <MenuItem value={'Contract Management'}>Contract Management</MenuItem>
                                            <MenuItem value={'UI/UX Consulting'}>UI/UX Consulting</MenuItem>
                                            <MenuItem value={'Artificial Intelligence'}>Artificial Intelligence</MenuItem>
                                            <MenuItem value={'Other'}>Other</MenuItem>
                                        </TextField>

                                    </Grid>

                                    <Grid item xl={12} lg={12} md={12} xs={12}>
                                        <TextareaAutosize
                                            placeholder="Message"
                                            minRows={7}
                                            style={{
                                                backgroundColor: "rgba(145, 158, 171, 0.08)",
                                                width: "100%",
                                                borderRadius: 16,
                                                borderColor: "gray",
                                                padding: 10,
                                                fontFamily: "inherit",
                                                fontSize: 16,
                                                border: 'none',
                                                marginLeft: 8,
                                            }}

                                            {...methods.register('message')}
                                        />
                                    </Grid>
                                    <Grid item container xl={12} lg={12} md={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                        <Button variant="contained" sx={{ borderRadius: '10px', py: 2, px: 7, backgroundColor: "#F47920", boxShadow: 5, my: { xs: 5, md: 0 } }} onClick={handleClose}>Close</Button>

                                        <Button type="submit" variant="contained" sx={{ borderRadius: '10px', py: 2, px: 7, backgroundColor: "#F47920", boxShadow: 5, my: { xs: 5, md: 0 } }}>Submit</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormProvider>
                    </Paper>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ContactUsModal