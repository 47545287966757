import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ChartIcon from '../../@svg/ChartIcon'

const OpenningCard = ({ data }: any) => {
    return (
        <Paper elevation={24} sx={{ py: 5 }} >
            <Box sx={{ px: 4 }} >
                <Typography variant='h6' sx={{ color: '#212B36', fontWeight: 600 }}>{data.position}</Typography>
                <Typography variant='body2' sx={{ color: '#F47920', fontWeight: 400, paddingTop: '10px' }}>No. of positions: {data.noOfPosition}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: "0px", paddingTop: '10px' }}>
                    <PlaceOutlinedIcon color="disabled" fontSize="small" />
                    <Typography variant='body2' sx={{ color: '#637381', paddingLeft: '5px' }} >{data.location}</Typography>
                </Box>
                <Box >
                    <Typography variant="body2" sx={{ color: '#637381', marginTop: '10px' }} >Posted On:  {data.postedDate} </Typography>
                    <List sx={{ listStyleType: 'disc', pl: 4, }}>
                        {data.requiredSkills.map((item: string, index: number) => (<ListItem key={index} disablePadding sx={{ display: 'list-item', fontSize: '12px' }}>
                            {item}
                        </ListItem>))}
                    </List>
                </Box>
            </Box>
            <Divider sx={{ height: 1, width: '100%', my: '2rem', borderStyle: 'dashed' }} />

            <Box sx={{ px: 5 }}>
                <Grid container sx={{ justifyContent: 'space-between' }}>

                    <Grid item xs={6} md={6} lg={6}>
                        <Box sx={{ display: 'flex' }}>
                            {/* <SignalCellularAltOutlinedIcon color="disabled" fontSize="small" /> */}
                            <ChartIcon />
                            <Typography variant='body2' sx={{ color: '#637381', paddingLeft: '5px' }}  >{data.experienceRequired} years exp</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <Box sx={{ display: 'flex' }}>
                            <AccessTimeOutlinedIcon color="disabled" fontSize="small" />
                            <Typography variant='body2' sx={{ color: '#637381', paddingLeft: '5px' }}  >{data.jobType}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Typography variant='body2' sx={{ color: '#F47920', fontWeight: 400, paddingTop: '30px', }}>Submit your CV to talent@sundeus.com</Typography>
            </Box>
        </Paper>
    )
}

export default OpenningCard