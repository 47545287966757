import * as React from "react"
import { SVGProps } from "react"

const ChartIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.375.5 10.25 2.844v4.062L8.375 5.5 5.25 7.844v4.062L3.375 10.5.25 12.844v3.906H1.5v-3.281l1.875-1.406 1.875 1.406v3.281H6.5V8.469l1.875-1.406 1.875 1.406v8.281h1.25V3.469l1.875-1.406 1.875 1.406V16.75h1.25V2.844L13.375.5Z"
                fill="#637381"
            />
        </svg>
    )
}

export default ChartIcon