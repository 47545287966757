import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

const data = ['Intutive & Smart User Interface',
    'Smart Initiation & Intelligent Contract Authoring',
    'Risk Mitigation and Capability to Enable AI driven analytics.',
    'One Touch Approvals, Review and Electronic Signatures.',
    'Version Control & Standard Clause Library Management.',
    'Configurable Alerts and Notifications.']

const Features = () => {
    const [buttonPressed, setButtonPressed] = useState()

    const ButtonPressed = (index: any) => {
        setButtonPressed(index);
        console.log(index);
    }

    return (
        <Grid sx={{ backgroundColor: '#161C24', }}>
            <Container maxWidth="lg" sx={{ py: 10 }}>
                <Grid container lg={12} md={12} xs={12} >
                    <Grid item lg={4} md={4} xs={12} sx={{ margin: { xs: 5 } }}>
                        <Typography variant='h3' sx={{ color: 'white', fontWeight: '700', my: "2rem" }}>Features</Typography>
                        <Typography variant='body2' sx={{ color: '#919EAB', width: '70%' }}>Since wire-frame renderings are relatively simple and fast to calculate, they are often used in cases</Typography>
                        <Button variant="contained" sx={{ my: '2.5rem', backgroundColor: '#FA541C', p: 2, fontSize: '15px', fontWeight: 600, }}><Link href="https://clm.sundeus.com/" sx={{ color: 'white' }}>Explore more</Link></Button>
                    </Grid>
                    <Grid container spacing={2} item lg={7} md={7} xs={12}>
                        {data.map((item: string, index: number) => (<Grid item key={index} lg={6} md={6} xs={12} >
                            <Box sx={{ borderRadius: 7, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'rgba(145, 158, 171, 0.24)', px: 5, height: '104px', pt: 3, backgroundColor: buttonPressed === index ? "#212B36" : "transparent", }} onClick={() => ButtonPressed(index)} >
                                <Typography variant='body1' sx={{ fontWeight: 600, color: buttonPressed === index ? "#FA541C" : "white", }} >{item}</Typography>
                            </Box>
                        </Grid>))}
                    </Grid>
                </Grid>
            </Container >
        </Grid >
    )
}

export default Features