import React from 'react'

const MonitorIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="none"
            viewBox="0 0 44 44"
        >
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.75"
                clipPath="url(#clip0_34_16)"
            >
                <path d="M36.667 7.333H7.333c-1.012 0-1.833.821-1.833 1.834V27.5c0 1.012.82 1.833 1.833 1.833h29.334c1.012 0 1.833-.82 1.833-1.833V9.167c0-1.013-.82-1.834-1.833-1.834zM12.833 36.667h18.334M16.5 29.333v7.334M27.5 29.333v7.334M16.5 22v-7.333M22 22v-1.833M27.5 22v-3.667M22 22v-1.833"></path>
            </g>
            <defs>
                <clipPath id="clip0_34_16">
                    <path fill="#fff" d="M0 0H44V44H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default MonitorIcon