import { Container, Divider, Grid, Typography } from '@mui/material';
import { openingsList } from './Data';
import OpenningCard from './OpenningCard';
import Carrer from "../../assets/career2.png"
import { Box } from '@mui/system';

const Career = () => {
    return (
        <Container maxWidth='lg' sx={{ py: 20 }}>
            <Grid container>
                <Grid item lg={6} xs={12} md={6} sx={{ alignItems: { xs: 'center', md: "flex-start", } }}>
                    <Typography variant='h3' sx={{ color: '#0B132A', fontWeight: "700", textAlign: { xs: 'center', md: 'start' }, pt: 5 }} >"Creativity at Core, Freedom to Innovate"</Typography>
                    <Divider color="#F47920" sx={{ height: 4, width: '170px', my: '1rem', alignItems: { xs: 'center' }, ml: { xs: 12, md: 0, lg: 0 } }} />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: { lg: 'flex-end', xs: 'center' }, px: { xs: '5' } }}>
                        <Box component="img" src={Carrer} alt="First Image" sx={{ maxWidth: { xs: '100%' } }} />
                    </Box>
                </Grid>
            </Grid>

            <Typography variant='h5' sx={{ fontWeight: '700', color: '#0B132A', my: 4 }} >CURRENT OPENINGS</Typography>
            <Grid container spacing={10} >

                {openingsList.map((item, index) => (<Grid key={index} item xs={12} md={6} lg={6} xl={6}>
                    <OpenningCard data={item} />
                </Grid>))}
            </Grid>
        </Container>
    )
}

export default Career