import React from 'react'

const DocumentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="none"
            viewBox="0 0 44 44"
        >
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.75"
                clipPath="url(#clip0_34_34)"
            >
                <path d="M18.333 22H16.5v9.167h1.833M25.667 22H27.5v9.167h-1.833M25.667 5.5v7.333a1.833 1.833 0 001.833 1.834h7.333"></path>
                <path d="M31.167 38.5H12.833a3.667 3.667 0 01-3.666-3.667V9.167A3.667 3.667 0 0112.833 5.5h12.834l9.166 9.167v20.166a3.667 3.667 0 01-3.666 3.667z"></path>
            </g>
            <defs>
                <clipPath id="clip0_34_34">
                    <path fill="#fff" d="M0 0H44V44H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default DocumentIcon