import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import ClientCorousel from "./ClientCorousel";


const logos = [
    { name: 'digivito', path: require('../../assets/digivito.png') },
    { name: 'wise-wings', path: require('../../assets/wide-wings.png') },
    { name: 'coxiant', path: require('../../assets/coxiant.png') },
    { name: 'benchmark', path: require('../../assets/benchmark.png') },
    { name: 'benchmark', path: require('../../assets/benchmark.png') },

]

const FeaturedClients = () => {
    return (
        <>
            <Paper elevation={24} sx={{ postion: 'absolute', mr: { xs: 0 }, mb: -20, mt: 10, p: 5, }}>
                <Typography variant='h4' sx={{
                    fontWeight: 500, textAlign: 'center', color: '#0B132A'
                }}>Featured Clients</Typography>
                <ClientCorousel />
            </Paper>
        </>
    )
}

export default FeaturedClients