import React from 'react'

const SettingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="none"
            viewBox="0 0 44 44"
        >
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.75"
                clipPath="url(#clip0_34_12)"
            >
                <path d="M18.93 7.915c.78-3.22 5.36-3.22 6.14 0a3.16 3.16 0 004.718 1.954c2.829-1.723 6.068 1.514 4.345 4.345a3.161 3.161 0 001.953 4.715c3.219.781 3.219 5.36 0 6.142a3.16 3.16 0 00-1.955 4.717c1.724 2.829-1.514 6.068-4.345 4.345a3.161 3.161 0 00-4.715 1.953c-.781 3.219-5.36 3.219-6.142 0a3.16 3.16 0 00-4.717-1.955c-2.829 1.724-6.068-1.514-4.345-4.345a3.161 3.161 0 00-1.953-4.715c-3.219-.781-3.219-5.36 0-6.142a3.16 3.16 0 001.955-4.717c-1.723-2.829 1.514-6.068 4.345-4.345a3.158 3.158 0 004.715-1.953z"></path>
                <path d="M22 27.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"></path>
            </g>
            <defs>
                <clipPath id="clip0_34_12">
                    <path fill="#fff" d="M0 0H44V44H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SettingIcon